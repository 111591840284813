$menu-height: 60px;

.c-header {
    background-color: $primary-color;
    color: $white;
    text-align: center;
    &__title {
        line-height: 1.3;
        transform: translateY(0.5rem);
    }
}

.c-skew-header {
    color: $grey30;
    &__top {
        background-color: $primary-color;
        height: $menu-height;
        position: relative;
        margin-top: -1px;
        z-index: 1;
        [class^="l-container"] {
            padding-right: 40px + $space-m !important;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            height: 100%;
            @media (min-width: $breakpoint-main) {
                padding-right: 60px + $space-m !important;
            }
        }
    }
    &__color {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $white;
        padding-right: 40px;
        font-size: $font-size-xs;
        text-transform: uppercase;
        @media (min-width: $breakpoint-main) {
            padding-right: 60px;
        }
    }
    &__bottom {
        position: relative;
        overflow: hidden;
        min-height: 110px;
        margin-top: -1px;
        z-index: 0;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 200%;
            width: 200%;
            background-color: $primary-color;
            transform: translate((tan(90deg - 72deg) * 100%), -(tan(90deg - 80deg) * 100%)) skew(80deg);
            z-index: -1;
            @media (min-width: $breakpoint-main) {
                transform: translate((tan(90deg - 72deg) * 100%), -(tan(90deg - 80deg) * 100%)) skew(85deg);
            }
        }
    }

    &__code {
        font-family: $font-family-heading;
        font-weight: $font-weight-medium;
        font-size: $font-size-m;
        color: $primary-color;
        transform: translateY(50%);
    }

    &__canvas {
        position: relative;
        transform: scale(0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        canvas {
            background-color: $primary-color;
            border-radius: 50%;
            border: 2px solid $white;
        }
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            border-radius: 50%;
            height: 124px;
            width: 124px;
            border: 5px solid $primary-color;
        }
    }
}
