// ----------
// Global
// ----------

.u-reset-list { @include reset-ul(); }

.u-overflow-hidden { overflow: hidden; }
.u-overflow-auto { overflow: auto; }

@for $i from 0 through 5 {
    .u-zi-#{$i} { z-index: $i; }
}
.u-zi--1 { z-index: -1; }

.u-br-base { border-radius: $border-radius-base; }

.u-transition { transition: all $transition-default; }

.u-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: inline-block;
}
