// Colors
.u-white {
	color: $white;
	&\@main { @media (min-width: $breakpoint-main) { color: $white; } }

}
.u-black {
	color: $black;
	&\@main { @media (min-width: $breakpoint-main) { color: $black; } }
}
.u-primary {
	color: $primary-color;
	&\@main { @media (min-width: $breakpoint-main) { color: $primary-color; } }
}
.u-grey50 {
	color: $grey50;
	&\@main { @media (min-width: $breakpoint-main) { color: $grey50; } }
}

.u-red25 {
	color: $red25;
	&\@main { @media (min-width: $breakpoint-main) { color: $red25; } }
}

.u-blue20 {
	color: $blue20;
	&\@main { @media (min-width: $breakpoint-main) { color: $blue20; } }
}

.u-blue30 {
	color: $blue30;
	&\@main { @media (min-width: $breakpoint-main) { color: $blue30; } }
}

.u-blue40 {
	color: $blue40;
	&\@main { @media (min-width: $breakpoint-main) { color: $blue40; } }
}

.u-text-color { color: $text-color; }

.u-success { color: $success-color; }
.u-warning { color: $warning-color; }
.u-danger { color: $danger-color; }

// Background-color
.u-bg-primary {
	background-color: $primary-color !important;
	&\@main { @media (min-width: $breakpoint-main) { background-color: $primary-color !important; } }
}
.u-bg-black {
	background-color: $black;
	&\@main { @media (min-width: $breakpoint-main) { background-color: $black !important; } }
}
.u-bg-white {
	background-color: $white;
	&\@main { @media (min-width: $breakpoint-main) { background-color: $white !important; } }
}
.u-bg-grey50 {
	background-color: $grey50;
	&\@main { @media (min-width: $breakpoint-main) { background-color: $grey50 !important; } }
}
.u-bg-blue20 {
	background-color: $blue20;
	&\@main { @media (min-width: $breakpoint-main) { background-color: $blue20 !important; } }
}
.u-bg-blue30 {
	background-color: $blue30;
	&\@main { @media (min-width: $breakpoint-main) { background-color: $blue30 !important; } }
}
.u-bg-blue40 {
	background-color: $blue40;
	&\@main { @media (min-width: $breakpoint-main) { background-color: $blue40 !important; } }
}
