// ----------
// Display, position, alignment
// ----------

.u-relative {
    position: relative;
    &\@main { @media (min-width: $breakpoint-main) { position: relative !important; } }

}
.u-absolute {
    position: absolute;
    &\@main { @media (min-width: $breakpoint-main) { position: absolute !important; } }
}

.u-hide {
    display: none;
    &\@main { @media (min-width: $breakpoint-main) { display: none !important; } }
}
.u-block {
    display: block !important;
    &\@main { @media (min-width: $breakpoint-main) { display: block !important; } }
}

.u-mobile-only {
    display: block;
    @media (min-width: $breakpoint-main) { display: none; }

}
.u-flex-mobile-only {
    display: flex;
    @media (min-width: $breakpoint-main) { display: none; }

}
.u-desktop-only {
    display: none;
    @media (min-width: $breakpoint-main) { display: block; }

}
.u-flex-desktop-only {
    display: none;
    @media (min-width: $breakpoint-main) { display: flex; }

}
.u-inline-block {
    display: inline-block;
    &\@main { @media (min-width: $breakpoint-main) { display: inline-block !important; } }
}

.u-right 	{
	text-align: right;
	&\@main { @media (min-width: $breakpoint-main) { text-align: right !important; } }
}
.u-center 	{
	text-align: center;
	&\@main { @media (min-width: $breakpoint-main) { text-align: center !important; } }
}
.u-left 	{
	text-align: left;
	&\@main { @media (min-width: $breakpoint-main) { text-align: left !important; } }
}

// ----------
// Flex helpers
// ----------

.u-flex {
    display: flex;
    &\@main { @media (min-width: $breakpoint-main) { display: flex !important; } }
}

.u-flex-0               {   flex: 0 0 auto;                        }
.u-flex-1               {   flex: 1 1 auto;                        }
.u-flex-between         {
    justify-content: space-between;
    &\@main { @media (min-width: $breakpoint-main) { justify-content: space-between !important; } }
}
.u-flex-around          {   justify-content: space-around;  }
.u-flex-center-hz       {
    justify-content: center;
    &\@main { @media (min-width: $breakpoint-main) { justify-content: center !important; } }
}
.u-flex-start           {
    justify-content: flex-start;
    &\@main { @media (min-width: $breakpoint-main) { justify-content: flex-start !important; } }
}
.u-flex-start-vt        {
    align-items: flex-start;
    &\@main { @media (min-width: $breakpoint-main) { align-items: flex-start !important; } }
}
.u-flex-end  {
    justify-content: flex-end;
    &\@main { @media (min-width: $breakpoint-main) { justify-content: flex-end !important; } }
}
.u-flex-end-vt          {   align-items: flex-end;          }
.u-flex-center-vt       {
    align-items: center;
    &\@main { @media (min-width: $breakpoint-main) { align-items: center !important; } }
}
.u-flex-strech          {   align-items: stretch;           }

.u-flex-dir-col {
    flex-direction: column;
    &\@main { @media (min-width: $breakpoint-main) { flex-direction: column !important; } }
}
.u-flex-dir-row {
    flex-direction: row;
    &\@main { @media (min-width: $breakpoint-main) { flex-direction: row !important; } }
}
.u-flex-dir-row-reverse {
    flex-direction: row-reverse;
    &\@main { @media (min-width: $breakpoint-main) { flex-direction: row-reverse !important; } }
}
.u-flex-wrap { flex-wrap: wrap; }
.u-flex-nowrap { flex-wrap: nowrap }

.u-flex-basis-full {
    flex: 0 0 100%;
    &\@main { @media (min-width: $breakpoint-main) { flex: 0 0 100%; } }
}

.u-flex-basis-auto {
    flex: 0 1 auto;
    &\@main { @media (min-width: $breakpoint-main) { flex: 0 1 auto; } }
}

// ----------
// Specific layout
// ----------

.u-height-full-viewport { height: 100vh; }
.u-height-full { height: 100%; }
.u-width-full { width: 100%; }

.u-top-0 { top: 0; }
.u-right-0 { right: 0; }
.u-left-0 { left: 0; }
.u-bottom-0 { bottom: 0; }
