* {
    -webkit-font-smoothing: antialiased;
}

@mixin heading() {
    font-family: $font-family-heading;
    margin: 0;
    line-height: 1;
}

.c-h1 {
    @include heading();
    font-size: rem($font-size-xl);

}

.c-h2 {
    @include heading();
    font-size: rem($font-size-l);
}

.c-h3 {
    @include heading();
    font-size: rem($font-size-s);
}

.c-h4 {
    @include heading();
    font-size: rem($font-size-xs);
}

.c-section-title {
    position: relative;
    overflow: hidden;
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        line-height: 0;
        min-width: 100%;

        .c-h2 {
            line-height: 0.7;
            margin-bottom: 2px;
            padding-top: 8px; // leave space for the accent
        }

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100vw;
            height: 2px;
            margin-top: -2px;
            background-color: currentColor;
        }
    }
}