.c-tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $primary-color;

    &__tab {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-s;
        font-family: $font-family-heading;
        min-height: 50px;
        position: relative;

        @include focus();

        &.is-active {
            font-weight: $font-weight-bold;
            @include add-arrow('top', 10px, $blue40, 1px, $primary-color, 50%);
            @media (min-width: $breakpoint-main) {
                @include add-arrow('top', 10px, $white, 1px, $primary-color, 50%);
            }
            &:before,
            &:after {
                bottom: 0;
                right: 0;
                left: 50%;
                margin-bottom: -1px;
            }
        }

        &:not(.is-active) {
            color: $blue20;
        }

        &:hover {
            cursor: pointer;
        }
    }
}