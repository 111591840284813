$menu-width: 270px;
$menu-height: 60px;

.u-pd-top-navbar { padding-top: $menu-height !important; }

.c-menu-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $menu-height;
    z-index: 11;
    @media (min-width: $breakpoint-main) {
        padding-left: calc(100% / 3);
    }

    &.no-aside {
        padding-left: 0;
    }

    &__navbar {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        background-color: $primary-color;
        @media (min-width: $breakpoint-main) {
            color: $white;
            justify-content: flex-end;
        }

        .light-header & {
            color: $primary-color;
            background-color: $body-background;
            @media (min-width: $breakpoint-main) {
                background-color: $white;
            }
        }
    }

    &__logo {
        display: flex;
        justify-content: center;
        height: 100%;
        svg {
            display: none;
            .light-header & {
                @media (max-width: $breakpoint-main - 1) {
                    display: block;
                    height: 100%;
                    width: auto;
                    max-width: 180px;
                    color: $primary-color;
                }
            }
        }
    }

    &__menu {
        position: absolute;
        top: 0;
        right: 0;
        height: 100vh;
        width: $menu-width;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        background-color: $body-background;
        border: 2px solid $primary-color;
        transform: translateX(100%);
        transition: transform $transition-default;
        z-index: 12;
    }

    &__toggle {
        position: relative;
        z-index: 13;
        transform: translateX(0);
        transition: transform $transition-default;
        @include focus();
        color: $white;

        .light-header & {
            color: $primary-color;
        }
    }

    $this: &;

    // State
    &.is-open {
        #{$this}__menu {
            transform: translateX(0);
        }
        #{$this}__toggle {
            transform: translateX(calc(-#{$menu-width} + #{$space-xl}));
            svg g {
                fill: $white !important;
            }
        }
    }

    // Header
    &__header {
        flex: 0 0 110px;
        height: 110px;
        background-color: $primary-color;
        color: $white;
        display: flex;
        align-items: flex-end;
        position: relative;
    }

    &__title {
        margin: 0;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        font-size: $font-size-xl;
        font-family: $font-family-heading;line-height: 1;
        margin-bottom: -5px;
    }

    // content
    &__content {
        flex: 1 0;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    &__links {
        display: flex;
        flex-direction: column;
    }

    &__item {
        display: flex;
        font-size: $font-size-s;
        @include focus();
        @media (min-width: $breakpoint-main) {
            padding: 0 $space-m
        }
    }

    &__item + &__item {
        @include add-vertical-separator(
            $position: 'before',
            $height: 40%,
            $width: 1px,
            $background: $primary-color,
            $mobile: 'false',
            $desktop: 'true'
        )
    }

    // footer
    &__footer {
        flex: 0 0 auto;
    }
}

.c-visual-highlight {
    position: relative;
    &:after {
        content: "1";
        font-size: 10px;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: $danger-color;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -30%);
    }
}