@import "styles/variables.scss";

.internationalization {
  z-index: 2;
  .internationalization__switch-btn {
    margin-left: 0;

    .internationalization__earth-icon {
      margin-right: 0.625rem;
      width: 1.875rem;
    }

    span {
      margin-left: 0.625rem;
    }
  }
}

.internationalization__modal {
  li {
    cursor: pointer;
    list-style: none;
    transition-property: background-color, color;
    transition-duration: 0.1s;
    transition-timing-function: linear;

    &:not(:last-child) {
      border-bottom: 1px solid $grey60;
    }

    &:hover {
      background-color: $grey50;
    }

    &.active {
      background-color: $grey60;
    }
  }
  button {
    height: 100%;
    width: 100%;
    padding: 5px 15px;

    .internationalization__country-label {
      cursor: pointer;
      flex-grow: 3;
      width: 100%;
      color: $primary-color;
    }
    .internationalization__country-input {
      position: absolute;
      right: 0;
      top: 0.85rem;
      cursor: pointer;
      width: 3.125rem;
      margin-left: auto;
      min-height: 1.25rem;
      flex-grow: 1;

      &:checked {
        accent-color: $primary-color;
      }
    }
  }
}
