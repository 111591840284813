$form-background: $blue30;
$form-color-primary: $primary-color;
$form-color-secondary: $blue20;
$form-height: 50px;
$form-font-size: $font-size-xs;

// Shared styles for forms
%form-appearance {
    border: 0;
    border-radius: 0;
    background-color: $form-background;
    color: $form-color-primary;
    font-size: $form-font-size;
    min-height: $form-height;
    text-align: left;

    padding: $space-s;

    @include placeholder($form-color-secondary);

    &.has-error {
        border-color: 1px solid $danger-color;
    }

    &[disabled] {
        background-color: $grey60;
        cursor: not-allowed;
        // by default IOS device add a 0.4 opacity on disabled input, modifying the color
        opacity: 1;
    }
}

.c-label {
    display: block;
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
    color: $form-background;
    text-transform: uppercase;
    margin: 0;
    font-size: $form-font-size;
    @media (min-width: $breakpoint-main) {
        font-size: $font-size-base;
    }
}

.c-input {
    @extend %form-appearance;
    display: block;
    width: 100%;
    padding-left: 2rem;

    & + button {
        position: absolute;
        right: 10px;
        top: 25px;
    }
}

.c-textarea {
    @extend %form-appearance;
    display: block;
    width: 100%;
    min-height: 85px;
    resize: none;
}

// Checkbox
// -----------

.c-checkbox {
    position: relative;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    appearance: none;
    border: 1px solid $primary-color;
    outline: 0 !important;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 50%;
        bottom: 50%;
        left: 50%;
        transition: all .1s;
    }

    &:checked:before {
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
    }
    &:checked:after {
        display: block;
        position: absolute;
        top: 1px;
        left: 6px;
        width: 6px;
        height: 12px;
        border: solid $black;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    &:hover,
    & + label:hover {
        cursor: pointer;
    }
}

// Radio
// -----------

.c-radio {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 40px;
    font-weight: $font-weight-bold;
    font-size: $font-size-s;

    &:hover {
        cursor: pointer;
    }

    &:before,
    &:after {
        content: '';
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        display: flex;
        align-items: center;
    }

    &:before {
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
        border: 1px solid $primary-color;
    }

    &.is-checked:before {
        border-color: $primary-color;
    }

    &.is-checked:after {
        width: 10px;
        height: 10px;
        transform: translate(50%, -50%);
        border-radius: 50%;
        background-color: $primary-color;
        border-color: $primary-color;
    }
}

// Select
// -----------

.c-select-list {
    position: absolute;
    width: 100%;
    top: -$form-height;
    left: 0;
    background: $form-background;
    border: 1px solid $form-color-secondary;
    max-height: 200px;
    overflow: auto;
    z-index: 99;
}

.c-select {
    @extend %form-appearance;

    & > &__control {
        border: 0;
        background-color: transparent;
        color: inherit;
        box-shadow: none;
        border-radius: 0;
    }

    & &__indicator {
        color: $black;
        &-separator {
            display: none;
        }
    }

    & > &__menu {
        margin: 0;
        left: 0;
        box-shadow: none;
        border-radius: 0;
        background: $form-background;
        border: 1px solid $form-color-secondary;
        z-index: 99;
    }

    & &__menu-list {
        max-height: 200px;
    }

    & &__option {
        cursor: pointer;
    }

    .is-inactive & {
        pointer-events: none;
    }
}

// Phone
// -----------
.PhoneInput {
    background-color: $form-background;

    &.is-disabled {
        background-color: $grey60;
        cursor: not-allowed;
        // by default IOS device add a 0.4 opacity on disabled input, modifying the color
        opacity: 1;
    }

    .PhoneInputInput {
        border: 0;
        background-color: transparent;
    }
    
    .PhoneInputCountrySelectArrow {
        border-style: solid;
        border-top-width: 0;
        border-left-width: 0;
        border-bottom-width: 1px;
        border-right-width: 1px;
    }
}

// Dropdown
// (still dependent from ReactStrap)
// -----------

.c-dropdown {
    &__toggle {
        @extend %form-appearance;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-left: 2rem;
        margin: 0;
        &:after {
            margin-right: 0.5rem;
            transform: scale(1.5);
        }
        .show &,
        &:focus,
        &:hover {
            border: 0 !important;
            background-color: $form-background !important;
            color: $form-color-primary !important;
            box-shadow: none !important;
        }
    }

    &__menu {
        &:before { display: none; }
        top: -3px !important;
        width: 100%;
        background: $form-background;
        border: 1px solid $form-color-secondary;
        max-height: 210px;
        overflow: auto;
        box-shadow: none !important;
        margin-top: -1px;
        margin-left: 1px;
        border-radius: 0;
        font-size: $form-font-size;
    }
}

// Autocomplete
// (dependent from bootstrap and Typehead)
// -----------

.c-autocomplete {
    .form-control {
        @extend %form-appearance;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-left: 2rem;
        padding-right: 34px;
        margin: 0;
        &:after {
            margin-right: 0.5rem;
            transform: scale(1.5);
        }
        .show &,
        &:focus,
        &:hover {
            border: 0 !important;
            background-color: $form-background !important;
            color: $form-color-primary !important;
            box-shadow: none !important;
        }
    }
    .toggle-button {
        background: transparent;
        border: 0;
        bottom: 0;
        padding: 0 20px;
        position: absolute;
        right: 0;
        top: 0;
    }
    .dropdown-menu {
        &:before { display: none; }
        top: -3px !important;
        width: 100%;
        background: $form-background;
        border: 1px solid $form-color-secondary;
        max-height: 210px;
        overflow: auto;
        box-shadow: none !important;
        margin-top: -1px;
        margin-left: 1px;
        border-radius: 0;
        font-size: $form-font-size;
    }
}


// Datepicker
// -----------

.c-datepicker {
    @extend %form-appearance;
    padding-left: 2rem;
    width: 100%;
    cursor: pointer;
}

// react datepicker dependency
.c-form-group .react-datepicker {
    border-radius: 0;
    
    border: 1px solid var(--primaryColor, $primary-color);
    &__header {
        background-color: var(--primaryColor, $primary-color);
        border-radius: 0 !important;
        padding: 12px 0;
    }
    &__triangle::before,
    &__triangle::after {
        border-bottom-color: var(--primaryColor, $primary-color) !important;
    }

    &__navigation-icon--previous,
    &__navigation-icon--next {
        &::before {
            top: 18px;
            border-color: $white;
            border-width: 2px 2px 0 0;
        }
    }

    &__navigation,
    &__current-month,
    &__day-name {
        color: $white;
    }
    &__day--keyboard-selected {
        background-color: $white;
        color: currentColor;
        border: 2px solid $black;
    }
    &__day--selected {
        background-color: var(--primaryColor, $primary-color);
        color: $white;
        border: 0;
    }

    // Year dropdown
    &__year-read-view {
        display: flex;
        flex-direction: row-reverse;
        align-content: center;
        justify-content: center;
    }
    &__year-read-view--selected-year {
        color: $white;
    }
    &__year-read-view--down-arrow {
        /*margin: 0 0 0 6px;*/
        top: 5px;
        border-width: 2px 2px 0 0;
        border-color: white;
    }

    .react-datepicker__navigation {
        &--years-upcoming,
        &--years-previous {
            line-height: 1.7rem;
            text-align: center;
            cursor: pointer;
            width: 0;
            border: 0.45rem solid transparent;
            z-index: 1;
            height: 18px;
        }

        &--years-upcoming {
            border-bottom-color: var(--primaryColor, $primary-color);
        }

        &--years-previous {
            border-top-color: var(--primaryColor, $primary-color);
        }
    }
    

    &-wrapper {
        width: 100%;
    }
}


// Group
// -----------

.c-form-group {
    position: relative;
    .c-label {
        line-height: 1;
        margin-bottom: -3px;
    }
    &--picto {
        .c-input-picto {
            position: absolute;
            bottom: 16px;
            left: 8px;
        }
    }
}

.c-btn-form-group {
    @include focus();
    height: $form-height;
    border-radius: 50%;
    border: 5px solid $body-background;
    transform: translateX(-50%);
    @media (min-width: $breakpoint-main) {
        border-color: $white;
    }
    &--lg {
        height: 70px;
        transform: translateX(-50%) translateY(10px);
    }
}

// Alternative form group
// -----------

.c-alternative-form-group {
    position: relative;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%,calc(#{$form-font-size} / 2 - 50%));
        border-radius: 50%;
    }
    &:before {
        content: "";
        height: 54px;
        width: 54px;
        border: 5px solid $blue40;
    }
    &:after {
        content: "Ou";
        height: 44px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-family-heading;
        font-size: $font-size-xs;
        color: $primary-color;
        border: 2px solid $blue30;
        background-color: $blue40;
    }

    .c-form-group:nth-child(2) {
        .c-label {
            display: none;
        }
    }

    &--white {
        &:after {
            @media (min-width: $breakpoint-main) {
                background-color: $white;
            }
        }
        &:before {
            @media (min-width: $breakpoint-main) {
                border-color: $white;
            }
        }
    }
}

