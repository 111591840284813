/**********************
****** Options *******
**********************/


// Please align HEX code with colors.js
$white: #fff;
$black: #1F1F1F;

$grey30: #F2F3F4;
$grey50: #EFF2F9;
$grey60: #DDE3EE;
$grey70: #BBBBBB;

$blue10: #385680;
$blue20: #92B1DB;
$blue25: #D0D6F4;
$blue30: #BBCCE7;
$blue40: #F2F3F4;

$red25: #E53935;

$font-family-base: 'Poppins', Arial, sans-serif;
$font-family-heading: 'Jost', $font-family-base;

$line-height-base: 1.4;

$font-size-xxs: 10px;
$font-size-xs: 14px;
$font-size-s: 18px;
$font-size-m: 22px;
$font-size-l: 25px;
$font-size-xl: 30px;
$font-size-xxl: 58px;
$font-size-base: $font-size-s;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$space-default: 16px;
$space-xxs: 2px;
$space-xs: 4px;
$space-s: 8px;
$space-m: 16px;
$space-l: 32px;
$space-xl: 48px;
$space-xxl: 96px;

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1430px;

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;
$breakpoint-main: $breakpoint-lg;

$border-radius-base: 4px;

$transition-time-default: 0.25s;
$transition-easing-default: cubic-bezier(.17,.67,.52,.97);
$transition-easing-pop: cubic-bezier(.07,.74,.13, 1.18);
$transition-default: $transition-time-default $transition-easing-default;

/**********************
****** Objects *******
**********************/

$primary-color: $blue10;
$text-color: $primary-color;

$success-color: #76a973;
$warning-color: #ffce00;
$danger-color: #C53762;

$html-font-size-root: $font-size-base;
$html-font-size-root-mobile: $font-size-base;

$body-background: $blue40;
$body-line-height: $line-height-base;
$body-font-family: $font-family-base;
$body-color: $text-color;

$link-text-color: currentColor;
$link-text-decoration: none;
$link-hover-color: $primary-color;
$link-hover-decoration: none;

$hr-margin-top: $space-s;
$hr-margin-bottom: $space-s;
$hr-size: 1px;
$hr-color: $text-color;
