@charset "utf-8";

@import

    // Import Debt
    "~bootstrap/scss/bootstrap",
    "debt/now-ui-dashboard",

	// Import Global
    "fonts",
    "variables",
    "global/functions",
    "global/mixins",
    "global/base",

    // Import Basics
    "grids/container",
    "grids/grids",

    // Import components
    "components/typography",
    "components/buttons",
    "components/form",
    "components/item",
    "components/tabs",
    "components/menu",
    "components/footer",
    "components/help",
    "components/scan",
    "components/header",
    "components/spinner",
    "components/table",
    "components/owallet",

    // Import Specificity
    "utilities/accessibility",
    "utilities/color",
    "utilities/global",
    "utilities/layout",
    "utilities/typography",
    "utilities/spacing",
    "shame"

;

body {
  background-color: $body-background;
}
