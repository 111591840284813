@mixin container-basis() {
	position: relative;

	margin-right: auto;
	margin-left: auto;
	max-width: 100%;
	width: 100%;

	// create new stacking context
	z-index: 0;

}

@mixin make-container($breakpoint) {

	@if $breakpoint == 'sm' {
		@media (min-width: $breakpoint-sm) {
			width: $container-sm;
		}
	}

	@if $breakpoint == 'md' {
		@media (min-width: $breakpoint-md) {
			width: $container-md;
		}
	}

	@if $breakpoint == 'lg' {
		@media (min-width: $breakpoint-lg) {
			width: $container-lg;
		}
	}

	@if $breakpoint == 'xl' {
		@media (min-width: $breakpoint-xl) {
			width: $container-xl;
		}
	}

	@if $breakpoint == 'xxl' {
		@media (min-width: $breakpoint-xxl) {
			width: $container-xxl;
		}
	}
}

.l-container {

	@include container-basis();

	// @include make-container('sm');
	// @include make-container('md');
	@include make-container('lg');
	@include make-container('xl');
	@include make-container('xxl');

}

.l-container-sm {
	@include container-basis();
	@include make-container('sm');
}

.l-container-md {
	@include container-basis();
	@include make-container('sm');
	@include make-container('md');
}

.l-container-lg {
	@include container-basis();
	@include make-container('sm');
	@include make-container('md');
	@include make-container('lg');
}

.l-container-xl {
	@include container-basis();
	@include make-container('sm');
	@include make-container('md');
	@include make-container('lg');
	@include make-container('xl');

}
