.c-scan {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    // new stacking context
    z-index: 0;

    &__video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        z-index: -1;
    }
    &__canvas {
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%;
    }
    &__mire {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__mire-overlay {
        position: absolute;
        display: block;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: 6px solid $primary-color;
        box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.25);
        z-index: 1;
    }
}