$button-font-family: $font-family-base;
$button-font-weight: $font-weight-bold;
$button-font-size: $font-size-base;
$button-height: 50px;
$button-radius: 10px;

a,
button {
	@include focus();
}

@mixin button-basis() {
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: center;
	min-height: $button-height;
	padding: $space-s;
	text-align: center;
	font-family: $button-font-family;
	font-weight: $button-font-weight;
	font-size: $button-font-size;
	border-radius: $button-radius;
	border: 2px solid;
	min-width: 120px;
	max-width: 100%;

	@include focus();

	&:hover,
	&:focus {
		cursor: pointer;
		outline: 0;
	}

}

@mixin button-variant(
		$color,
		$background,
		$border,
		$color-hover: $color,
		$background-hover: darken($background, 10%),
		$border-hover: darken($border, 10%)) {

	color: $color;
	background-color: $background;
	border-color: $border;

	&:hover,
	&:focus {
		color: $color-hover;
		background-color: $background-hover;
		border-color: $border-hover;
	}

	&[disabled] {
		opacity: 0.2;
		pointer-events: none;
	}

}


.c-btn {
	@include button-basis();

	// Modifiers (business)
	&--primary {
		@include button-variant($primary-color, $white, $primary-color);
	}

	// Shape
	&--rounded {
		width: $button-height;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		min-width: auto;
	}

}

.c-btn-back {
	position: fixed;
	top: 10px;
	left: 10px;
	z-index: 12;
	color: $white;
	@media (min-width: $breakpoint-main) {
		left: calc(100% / 3 + 10px);
	}

	.light-header & {
		color: $primary-color;
	}
}
