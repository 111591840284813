.c-item {
    display: flex;
    position: relative;
    width: 100%;
    height: 60px;
    font-family: 'Arial';
    background-color: $white;
    @media (min-width: $breakpoint-main) {
        background-color: $blue40;
    }

    &:hover {
        cursor: pointer;
    }

    $this: &;

    &__status {
        font-size: $font-size-s;
        font-weight: $font-weight-bold;
        font-family: $font-family-heading;
        text-transform: uppercase;
        line-height: 1;
        position: absolute;
        top: 3px;
        left: 0;
        transform: translateY(-100%);
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 0 1 80%;
        font-size: $font-size-xs;
        font-family: $font-family-base;
        color: $white;
        padding: $space-s $space-m $space-s 2rem;
        position: relative;
        overflow: hidden;
        z-index: 0;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $primary-color;
            transform: translateX((tan(35deg) * -60px) / 2) skew(35deg);
            z-index: -1;
        }
    }

    &__picto {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 1 20%;
    }

    // State
    &--black {
        background-color: $grey70;
        #{$this}__status {
            color: $black;
        }
        #{$this}__content {
            &:before {
                background-color: $black;
            }
        }
        #{$this}__picto {
            color: $black;
        }
    }


}
