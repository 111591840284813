.u-sticky-view {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .c-main-content {
        // set flex-grow: 1 + explicity set flex-shrink to 0 for Safari (its default value is 1)
        flex: 1 0 auto;
        width: 100%;
    }
}

.l-form-width {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 360px;
    @media (min-width: $breakpoint-main) {
        max-width: 400px;
    }
}

.l-form-width-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 400px;
    @media (min-width: $breakpoint-main) {
        max-width: 500px;
    }
}

.c-logo {
    &--lg {
        width: 300px;
        height: 50px;
        &.is-vertical {
            width: 200px;
            height: 150px;
        }
    }
}


// https://stackoverflow.com/questions/26091989/svg-viewbox-height-issue-on-ios-safari
.u-svg-preserve-ratio {
    padding-bottom: 45%; /* height/width ratio */
    height: 0;
    position: relative;
    svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.c-or {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    span {
        z-index: 1;
        display: inline-block;
        background-color: $white;
    }
    &:before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}