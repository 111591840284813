.c-help {
    background-color: $primary-color;
    color: #fff;
    text-align: center;
    max-width: 295px;
    min-height: 120px;
    border-radius: 25px;
    padding: $space-m;
    font-size: $font-size-xs;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 10px;

    h3 {
        text-transform: uppercase;
        font-size: $font-size-s;
        font-weight: $font-weight-bold;
    }

    a {
        display: inline-block;
    }
}