.c-footer {

    .hide-footer & {
        display: none;
    }

    &__illustration {
        position: relative;
        z-index: 0;
        &:before {
            content: "";
            background-color: $primary-color;
            height: 73px;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 100vw;
            z-index: -1;
        }

        svg {
            width: 400px;
            height: 180px;
        }
    }

    // &__illustration {
    //     color: purple;
    //     &:before {
    //         content: "";
    //         background-color: $primary-color;
    //         height: 6.1vw;
    //         position: absolute;
    //         left: 0;
    //         bottom: 0;
    //         width: 100%;
    //     }
    // }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $primary-color;
        color: $white;
        font-size: $font-size-xs;
        @media (min-width: $breakpoint-main) {
            flex-direction: row;
            justify-content: center;
            // height is based on the aside-footer svg which is based on vw
            min-height: 6.1vw;
        }
    }

}